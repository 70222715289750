import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Header from './header';
import { additionalColors } from '../../theme';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '75vh',
        [theme.breakpoints.down('sm')]: {
            background: 'linear-gradient(181.86deg, #5DC0FE 20.61%, #5DC0FE 41.12%, #fff 75%)',
            padding: '1vh',
        },
        '&.hideBgGradient': {
            [theme.breakpoints.down('sm')]: {
                background: 'none',
                padding: 0,
            },
        }
    },
    content: {
        backgroundColor: 'white',
        borderRadius: '0.375rem',
        minHeight: '73vh',
        [theme.breakpoints.down('sm')]: {
            borderRadius: 'unset',
        },
        '&.hideBgGradient': {
            [theme.breakpoints.down('sm')]: {
                minHeight: 'none',
                padding: 0,
            },
        }
    },
    contentTransparent: {
        backgroundColor: 'transparent',
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}));

const useStylesDryJan = makeStyles(() => ({
    root: {
        backgroundColor: additionalColors.primaryPurple,
        minHeight: '100vh',
    }
}));

type Props = {
    children: JSX.Element[] | JSX.Element,
    transparentBackground?: boolean,
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
    overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'unset',
    hideBgGradient?: boolean,
    preElement?: JSX.Element,
    sunnySide?: boolean,
    showMenu?: boolean,
    useDryJanTheme?: boolean
};

const defaultProps: Partial<Props> = {
    overflow: 'hidden'
};

export default function Layout(props: Props): JSX.Element {
    props = {...defaultProps, ...props};
    const classes = useStyles();
    const dryJanClasses = useStylesDryJan();

    const contentClass = props.transparentBackground ? 
        `${classes.content} ${classes.contentTransparent}`:
        `${classes.content}`;
    
    // TEMP: For sunnyside 'dual mode'
    const defaultRootClass = classes.root;

    const rootClass = props.hideBgGradient ? `${defaultRootClass} hideBgGradient` : `${defaultRootClass}`;

    return (
        <Box>
            <Header showMenu={props.showMenu} />
            <div className={`${rootClass} ${props.useDryJanTheme ? dryJanClasses.root : ''}`}>
                {props.preElement}
                <Container 
                    maxWidth={props.maxWidth || 'xs'} 
                    disableGutters 
                    component="main" 
                    className={contentClass} 
                    style={{ overflow: props.overflow }}>
                    <div className="min-h-screen">
                        {props.children}
                    </div>
                </Container>
            </div>
        </Box>
    );
}