import {
    AppBar,
    Toolbar,
    IconButton,
    Divider,
    List,
    ListItemButton,
    Hidden,
    ClickAwayListener,
    Typography,
    Box
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useState, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { TrackingItemSVGIcon, ProgressItemSVGIcon, ProfileItemSVGIcon, ChallengeSVGIcon } from '../svg/menu';
import { ampLogEvent } from '../../lib/amplitude';
import SunnysideWhiteLogo from '../../../public/icons/sunnyside/sunnyside-logo-white';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: theme.palette.primary.main,
        position: 'relative',
        zIndex: 200,
        '& h1, & h2, & h3, & h4, & h5, & h6': {
            fontFamily: 'Poppins',
        }
    },
    toolbar: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    logo: {
        width: 230,
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: theme.palette.primary.contrastText
    },
    menuHamburger: {
        position: 'absolute',
        width: '100%',
        background: theme.palette.primary.main,
        borderRadius: '0 0 30px 30px',
        color: 'white',
    },
    menu: {
        height: 64,
        color: 'white',
        padding: 0,
        display: 'flex'
    },
    activeMenuItem: {
        backgroundColor: '#33A2E8',
        '&:hover': {
            backgroundColor: '#2291D7'
        },
    },
    menuItem: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        '& h2': {
            position: 'relative',
            margin: '.25rem 0',
            fontWeight: 500,
            padding: '0 16px',
            fontSize: '1.125em',
            color: 'white'
        }
    },
    activeMenuIcon: {
        '& h2': {
            '&::before': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '3.5px',
                bottom: '-5px',
                left: 0,
                backgroundColor: '#FFE635',
                borderRadius: '100px'
            }
        }
    }
}));

type Props = {
    showMenu?: boolean
}

export default function Header({ showMenu }: Props): JSX.Element {
    const [cookies] = useCookies();

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuOffset, setMenuOffset] = useState(0);

    const classes = useStyles();
    const containerRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        ampLogEvent(
            'active: toggled menu',
            {},
            cookies
        );
    };

    useEffect(() => {
        if (containerRef.current) {
            setMenuOffset(containerRef.current.clientHeight);
        }
    }, [containerRef]);

    return (
        <>
            {showMenu && (
                <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                    <AppBar ref={containerRef} position="static" color="default" elevation={0} className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <Box paddingTop="5px">
                                <SunnysideWhiteLogo width="125" height="48" />
                            </Box>
                            <Hidden mdUp>
                                {menuOpen ? (
                                    <IconButton edge="end" style={{ color: 'white' }} onClick={toggleMenu}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton edge="end" style={{ color: 'white' }} onClick={toggleMenu}>
                                        <MenuRoundedIcon />
                                    </IconButton>
                                )}
                            </Hidden>
                            <Hidden mdDown>
                                <List className={classes.menu} component="nav">
                                    <NavLink href="/dashboard/tracking" mainClass={classes.menuItem} activeClass={classes.activeMenuItem} >
                                        <TrackingItemSVGIcon />
                                        <Typography variant='h2'>Tracking</Typography>
                                    </NavLink>
                                    <NavLink href="/dashboard/progress" mainClass={classes.menuItem} activeClass={classes.activeMenuItem} >
                                        <ProgressItemSVGIcon />
                                        <Typography variant='h2'>Progress</Typography>
                                    </NavLink>
                                    <NavLink href="/dashboard/challenge?tab=active" mainClass={classes.menuItem} activeClass={classes.activeMenuItem} >
                                        <ChallengeSVGIcon />
                                        <Typography variant='h2'>Challenges</Typography>
                                    </NavLink>
                                    <NavLink href="/dashboard" mainClass={classes.menuItem} activeClass={classes.activeMenuItem} >
                                        <ProfileItemSVGIcon />
                                        <Typography variant='h2'>Profile</Typography>
                                    </NavLink>
                                </List>
                            </Hidden>
                        </Toolbar>
                        <Hidden mdUp>
                            {menuOpen && (
                                <List className={`${classes.menuHamburger} `} component="nav" style={{ top: menuOffset }}>
                                    <Divider style={{ backgroundColor: 'rgb(255 255 255 / 12%)' }} />
                                    <NavLink
                                        href="/dashboard/tracking"
                                        mainClass={classes.menuItem}
                                        activeClass={classes.activeMenuIcon}
                                    >
                                        <Typography variant='h2'>Tracking</Typography>
                                    </NavLink>
                                    <NavLink
                                        href="/dashboard/progress"
                                        mainClass={classes.menuItem}
                                        activeClass={classes.activeMenuIcon}
                                    >
                                        <Typography variant='h2'>Progress</Typography>
                                    </NavLink>
                                    <NavLink
                                        href="/dashboard/challenge?tab=active"
                                        mainClass={classes.menuItem}
                                        activeClass={classes.activeMenuIcon}
                                    >
                                        <Typography variant='h2'>Challenges</Typography>
                                    </NavLink>
                                    <NavLink
                                        href="/dashboard"
                                        mainClass={classes.menuItem}
                                        activeClass={classes.activeMenuIcon}
                                    >
                                        <Typography variant='h2'>Profile</Typography>
                                    </NavLink>
                                </List>
                            )}
                        </Hidden>
                    </AppBar>
                </ClickAwayListener>
            )}
            {!showMenu && (
                <Hidden smDown>
                    <AppBar ref={containerRef} position="static" color="default" elevation={0} className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <SunnysideWhiteLogo width="125" height="48" />
                        </Toolbar>
                    </AppBar>
                </Hidden>
            )}
        </>
    );
}

type NavLinkProps = {
    href: string,
    mainClass: string,
    activeClass?: string,
    children: JSX.Element[] | JSX.Element
}

function NavLink(props: NavLinkProps): JSX.Element {
    const router = useRouter();
    const isActive = router.pathname === props.href;

    if (props.activeClass) {
        return (
            <ListItemButton
                className={isActive
                    ? `${props.mainClass} ${props.activeClass}`
                    : props.mainClass}
                onClick={() => router.push(props.href)}
            >
                {props.children}
            </ListItemButton>
        );
    } else {
        return (
            <ListItemButton
                className={props.mainClass}
                onClick={() => router.push(props.href)}
                selected={isActive}
            >
                {props.children}
            </ListItemButton>
        );
    }
}