import { useEffect, useState } from 'react';

export type AppUserAgent = {
    isApp: boolean,
    os: 'ios' | 'android' | undefined,
    rawUserAgent: string
}

/* Used as a fallback for ss_app cookie; logic should be removed upon in-app diagnostic */
export default function useAppUserAgent(): AppUserAgent | undefined {
    const [userAgent, setUserAgent] = useState<AppUserAgent>();

    const handleGetUserAgent = (rawUserAgent: string) => {
        const isApp = rawUserAgent.includes('sunnyside');
        const os = (): 'ios' | undefined => {
            switch(true) {
            case rawUserAgent.toLowerCase().includes('iphone'):
                return 'ios';
            default: return undefined;
            }
        };
        setUserAgent({ isApp, os: os(), rawUserAgent });
    };

    useEffect(() => {
        handleGetUserAgent(window.navigator.userAgent);
    }, []);

    return userAgent;
}
