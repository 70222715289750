export function TrackingItemSVGIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.980469 5.17208C0.980469 2.67536 3.00446 0.651367 5.50118 0.651367H7.19645C9.69317 0.651367 11.7172 2.67536 11.7172 5.17208V6.86735C11.7172 9.36407 9.69317 11.3881 7.19645 11.3881H5.50118C3.00446 11.3881 0.980469 9.36407 0.980469 6.86735V5.17208ZM13.4116 17.608C13.4116 15.1113 15.4356 13.0873 17.9324 13.0873H19.6276C22.1243 13.0873 24.1483 15.1113 24.1483 17.608V19.3032C24.1483 21.8 22.1244 23.824 19.6276 23.824H17.9324C15.4356 23.824 13.4116 21.8 13.4116 19.3032V17.608ZM0.980469 17.608C0.980469 15.1113 3.00446 13.0873 5.50118 13.0873H7.19645C9.69317 13.0873 11.7172 15.1113 11.7172 17.608V19.3032C11.7172 21.8 9.69317 23.824 7.19645 23.824H5.50118C3.00446 23.824 0.980469 21.8 0.980469 19.3032V17.608ZM24.6819 4.0132C25.1331 3.56201 25.1331 2.83049 24.6819 2.3793C24.2307 1.92811 23.4992 1.92811 23.048 2.3793L18.2141 7.21324L16.7707 5.76984C16.3195 5.31865 15.5879 5.31865 15.1368 5.76984C14.6856 6.22103 14.6856 6.95255 15.1368 7.40374L16.5802 8.84714C17.4825 9.74952 18.9456 9.74952 19.848 8.84714L24.6819 4.0132Z" fill="white"/>
        </svg>

    );
}

export function ProgressItemSVGIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4297 7.23633H14.1036C13.5603 7.23633 13.0817 6.95438 12.8547 6.50055C12.6276 6.04672 12.7062 5.5292 13.0599 5.14989L13.0721 5.13701L17.5965 0.495036C17.757 0.330391 17.9861 0.236328 18.2266 0.236328C18.467 0.236328 18.6961 0.330391 18.8566 0.495036L23.381 5.13701C23.3852 5.14124 23.3893 5.14556 23.3933 5.14989C23.7469 5.52915 23.8255 6.04672 23.5985 6.50055C23.3714 6.95438 22.8928 7.23633 22.3495 7.23633H21.0297V22.2371C21.0297 23.3417 20.1343 24.2371 19.0297 24.2371H17.4297C16.3251 24.2371 15.4297 23.3417 15.4297 22.2371V7.23633ZM2.27344 14.6367C1.16887 14.6367 0.273438 15.5321 0.273438 16.6367V22.2367C0.273438 23.3413 1.16887 24.2367 2.27344 24.2367H3.87344C4.97801 24.2367 5.87344 23.3413 5.87344 22.2367V16.6367C5.87344 15.5321 4.97801 14.6367 3.87344 14.6367H2.27344ZM7.85156 12.6367C7.85156 11.5321 8.74699 10.6367 9.85156 10.6367H11.4516C12.5561 10.6367 13.4516 11.5321 13.4516 12.6367V22.2367C13.4516 23.3413 12.5561 24.2367 11.4516 24.2367H9.85156C8.74699 24.2367 7.85156 23.3413 7.85156 22.2367V12.6367Z" fill="white"/>
        </svg>

    );
}

export function ProfileItemSVGIcon(): JSX.Element {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_11780_10775)">
                <path d="M12 0.237305C5.383 0.237305 0 5.6203 0 12.2373C0 18.8543 5.383 24.2373 12 24.2373C18.617 24.2373 24 18.8543 24 12.2373C24 5.6203 18.617 0.237305 12 0.237305ZM18.698 19.6413C18.034 17.6683 16.198 16.2373 14 16.2373H10C7.802 16.2373 5.968 17.6693 5.304 19.6423C3.281 17.8113 2 15.1743 2 12.2373C2 6.7233 6.486 2.2373 12 2.2373C17.514 2.2373 22 6.7233 22 12.2373C22 15.1733 20.72 17.8103 18.698 19.6413Z" fill="white"/>
                <path d="M11.8949 6.47949C9.74396 6.47949 8 8.02968 8 9.94167V10.8072C8 12.7192 9.74396 14.2694 11.8949 14.2694C14.0459 14.2694 15.7899 12.7192 15.7899 10.8072V9.94167C15.7899 8.02968 14.0459 6.47949 11.8949 6.47949Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_11780_10775">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.237305)"/>
                </clipPath>
            </defs>
        </svg>

    );
}

export function ChallengeSVGIcon(): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
            <path d="M21.2308 2.00631H20.1374C19.7552 0.934156 18.7398 0.160156 17.5385 0.160156H6.46154C5.25969 0.160156 4.24477 0.934156 3.86262 2.00631H2.76923C1.242 2.00631 0 3.24831 0 4.77554V6.62169C0 8.86477 1.61031 10.7349 3.73477 11.148C4.12385 15.0346 7.20323 18.1366 11.0769 18.5672V20.4678H8.30769C7.79815 20.4678 7.38462 20.8809 7.38462 21.3909C7.38462 21.9009 7.79815 22.314 8.30769 22.314H15.6923C16.2018 22.314 16.6154 21.9009 16.6154 21.3909C16.6154 20.8809 16.2018 20.4678 15.6923 20.4678H12.9231V18.5672C16.7968 18.1366 19.8762 15.0346 20.2652 11.148C22.3897 10.7349 24 8.86477 24 6.62169V4.77554C24 3.24831 22.758 2.00631 21.2308 2.00631ZM1.84615 6.62169V4.77554C1.84615 4.266 2.26015 3.85246 2.76923 3.85246H3.69231V9.22062C2.62015 8.83846 1.84615 7.82354 1.84615 6.62169ZM22.1538 6.62169C22.1538 7.82354 21.3798 8.83846 20.3077 9.22062V3.85246H21.2308C21.7398 3.85246 22.1538 4.266 22.1538 4.77554V6.62169Z" fill="white"/>
        </svg>
    );
}