import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { ParsedUrlQuery } from 'querystring';

import Layout from '../../components/core/layout';
import { ampLogEvent } from '../../lib/amplitude';
import SessionContext from '../../contexts/session';
import SunnysidePineLogo from '../../../public/icons/sunnyside/sunnyside-logo-pine';
import SunnysideButton from '../../components_sunnyside/button';
import { Data, getWebsiteDomain, post } from '../../networking';
import { activeFormId, redirectUserAfterLogin, refreshUserAndStoreIn } from '../../logic/auth';
import { colors } from '../../styles/_colors';
import { postCheckoutInfo } from '../../logic/pricing';
import useAppUserAgent from '../../components/core/use-app-user-agent';
import { brazeUserTrack } from '../../lib/braze';

async function postUser(payload?: Data): Promise<Data> {
    return await post('auth/createUser', payload);
}

const useStyles = makeStyles(() => ({
    wrapper: {
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    loginCta: {
        fontSize: '1rem'
    }
}));

interface LandingPageProps {
    couponResponse?: any,
    query: ParsedUrlQuery,
    setClickedSkippy: Dispatch<SetStateAction<boolean>>
}
const ENVIRONMENT = process.env.NEXT_PUBLIC_NENV;

const skippyEnvironment = new Set(['development', 'staging']);

/**
 * NOTE: When making changes to this landing page make sure you make the same changes to both,
 * landing_v1.tsx and landing_v2.tsx if you want the changes to show up on all landing pages
 */
export default function LandingPage(props: LandingPageProps): JSX.Element {
    const { query, setClickedSkippy } = props;
    const classes = useStyles();
    const router = useRouter();
    const [cookies, setCookies] = useCookies();
    const session = useContext(SessionContext);
    const [error, setError] = useState('');
    const appUserAgent = useAppUserAgent();

    const { useskippy } = router.query;

    // expires in one hour
    const expiresAt = new Date(new Date().getTime() + (60*60*1000));

    const postCheckoutData = async (coupon) => {
        const payload = {
            coupons: coupon,
        };
        await postCheckoutInfo(payload);
    };

    /**
     * Store utm_content and utm_source params within user cookie
     */
    useEffect(() => {
        if (query) {
            const { utm_content, utm_source, utm_medium, d } = query;
            if (utm_content) {
                setCookies('utm_content', utm_content, {
                    path: '/',
                    domain: getWebsiteDomain()
                });
            }
            if (utm_source) {
                setCookies('utm_source', utm_source, {
                    path: '/',
                    domain: getWebsiteDomain()
                });
            }
            if (utm_medium) {
                setCookies('utm_medium', utm_medium, {
                    path: '/',
                    domain: getWebsiteDomain()
                });
            }
            const domain = (new URL(process.env.NEXT_PUBLIC_SUNNYSIDE_API_HOST));
            const processedDomain = '.' + domain.hostname.match(/(\S+\.)?([\S]+\.com?)$/)[2];
            if (d) {
                setCookies('ss_discount', query.d, {
                    path: '',
                    expires: expiresAt,
                    domain: processedDomain
                }); 
                refreshUserAndStoreIn(session);
                if (session.user) {
                    postCheckoutData(query.d);
                }
            }
        }
    }, []);

    useEffect(() => {
        ampLogEvent(
            'signup: landed on onboarding start',
            {},
            cookies
        );
    }, []);

    const createUserOrError = async () => {
        if (setClickedSkippy) {
            setClickedSkippy(true);
        }
        const user: Data = {};
        if (cookies.utm_content && cookies.utm_source) {
            switch(cookies.utm_source)  {
            case 'invite': {
                user.referredBy = cookies.utm_content;
                user.referralType = 'user';
                break;
            }
            case 'ambassador': {
                user.referredBy = cookies.utm_content;
                user.referralType = cookies.utm_source;
                break;
            }
            }
        }
        if (cookies?.ss_app_device || appUserAgent?.isApp) {
            user.create_source = cookies?.ss_app_device || appUserAgent.os;
        }
        const userPostResponse = await postUser(user);
        // Properly check user creation and request token
        if (userPostResponse.id) {
            await refreshUserAndStoreIn(session);
            await brazeUserTrack({
                attributes: [
                    { utm_content: cookies?.utm_content ?? null },
                    { utm_source: cookies?.utm_source ?? null },
                    { utm_medium: cookies?.utm_medium ?? null }
                ]
            });
            // TODO - proper redirect on diagnostic flow after email registration
            return true;
        } else {
            setError(userPostResponse.message as string || 'Server error');
            return false;
        }
    };

    const handleSkippy = async () => {
        if (await createUserOrError()) {
            redirectUserAfterLogin(session.user, router, `/diagnostic/${activeFormId}/email`, activeFormId);
        }
    };

    return (
        <Layout>
            <Box p="2rem" className={classes.wrapper}>
                {props.couponResponse?.percent_off && (<Box style={{
                    backgroundColor: '#FBE75C',
                    borderRadius: '0.5rem',
                    marginBottom: '2rem',
                    textAlign: 'center',
                    width: '100%'
                }}>
                    {props.couponResponse.percent_off}% Discount Applied
                </Box>)}
                <Box>
                    <SunnysidePineLogo width="125" height="48" />
                    <Box my="2em">
                        <Typography variant="h3" color="black" fontSize='1.75rem'>Sunnyside&apos;s proven <strong>healthy drinking system</strong> is entirely personalized to you.</Typography>
                    </Box>
                    <Box my="1em">
                        <Typography>Get started in <strong>about 3 minutes</strong> with this Diagnostic Assessment.</Typography>
                    </Box>
                    <Box mt="2em">
                        <SunnysideButton
                            href='/diagnostic/HJmDjOmw/intro'
                            borderRadius="10px" 
                            width="auto"
                            fontSize='1.1rem'>
                            Get Started → 
                        </SunnysideButton>
                        {(skippyEnvironment.has(ENVIRONMENT) || useskippy) && (
                            <SunnysideButton 
                                onClick={handleSkippy}
                                bgcolor={colors.coral}
                                width="auto"
                                margin='1rem 1rem'>
                                SKIPPY
                            </SunnysideButton>
                        )}
                        {error && <Typography variant='body2'>{error}</Typography>}
                    </Box>
                    <Box mt="2em" className={classes.loginCta}>
                        <Link href="/login/phone">I already have an account. Log me in!</Link>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
}